//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { alertIcon } from "@debionetwork/ui-icons";
import AgreementDialog from "./AgreementDialog.vue";
import getEnv from "@/common/lib/utils/env";
export default {
  name: "NoLab",
  components: {
    AgreementDialog
  },
  data: () => ({
    showAgreement: false,
    showSuccessDialog: false,
    alertIcon
  }),
  computed: {
    ...mapState({
      country: state => state.lab.country,
      city: state => state.lab.city,
      category: state => state.lab.category,
      labs: state => state.lab.labs
    })
  },
  async mounted() {
    if (!this.labs) return;
    if (!this.labs.length) this.showNoLab = true;
  },
  methods: {
    showingAgreementDialog() {
      this.showAgreement = true;
    },
    getService() {
      this.showAgreement = false;
      this.$emit("fetch");
    },
    closingDialog() {
      this.showAgreement = false;
    },
    onClickDialogButton() {
      this.showAgreement = false;
      this.showSuccessDialog = true;
    },
    toStakeService() {
      this.$router.push({
        name: "my-test",
        params: {
          page: 1
        }
      });
    },
    toLabRequestService() {
      window.open("".concat(getEnv("VUE_APP_SERVICE_REQUEST_LINK")), "__blank");
    }
  }
};