//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getStates } from "@/common/lib/api";
export default {
  name: "MenuCard",
  data: () => ({
    state: ""
  }),
  props: {
    title: String,
    icon: String,
    labName: String,
    price: String,
    avatar: String,
    currency: String,
    rate: Number,
    countRate: Number,
    description: String,
    category: String,
    city: String,
    region: String,
    country: String
  },
  async mounted() {
    await this.getStateData();
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
    async getStateData() {
      const {
        data: {
          data
        }
      } = await getStates(this.country);
      const states = data;
      this.state = states.filter(s => s.state_code === this.region)[0].name;
    }
  }
};