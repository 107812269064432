//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SelectService from "./SelectService";
export default {
  name: "SelectServicePage",
  components: {
    SelectService
  },
  data: () => ({
    stepperItems: [{
      number: 1,
      title: "Select Location and Test Category",
      active: false
    }, {
      number: 2,
      title: "Select Service",
      active: true
    }, {
      number: 3,
      title: "Checkout & Payment",
      active: false
    }, {
      number: 4,
      title: "Summary/Instruction",
      active: false
    }],
    isSelectLocation: true,
    isSelectService: false,
    itosPaymentCheckout: false,
    isSuccessPage: false,
    staking: false
  }),
  async mounted() {
    if (this.$route.params.flag === "staking") {
      this.staking = true;
    }
  },
  methods: {
    handleBack() {
      this.$router.push({
        name: "customer-request-test"
      });
    },
    toPaymentCheckout() {
      this.$router.push({
        name: "customer-request-test-checkout"
      });
    }
  }
};